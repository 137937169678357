<template>
        <v-avatar :size="size" class="center">
            <v-img
                width="32"
                height="32"
                lazy-src="/images/user.png"
                :src="errorImage ? '/images/user-w.png' :  '/images/user-w.png'"
                alt="user avatar"
                class="sidebar-avatar"
                v-on:error="() => errorImage = true"
            >
                <template v-slot:placeholder>
                <v-row v-if="loading && !errorImage" class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
                </template>
            </v-img>
        </v-avatar>
</template>
<script>
export default {
    props : {
        size : {
            default : 32
        },
        id : {
            default : null,
        },
        loading: {
            default : true
        }
    },
    name: "s-profile",
    data(){
        return {
            errorImage : false
        }
    }
}
</script>
