<template>
<div>
  <v-app>
    <s-navigation-app-bar @toggle="drawer.toggle()">
      <template v-slot:aftermenu>
        <v-chip  color="black">
          <s-profile :size="32" :loading="false" class="mr-2" />
          <span class="text-white">{{ fullName }}</span>
        </v-chip>
        <s-translation-switcher />
        <v-btn
          id="logout"
          :to="localePath('/logout')"
          icon
          style="color: white"
        >
          <v-icon>mdi-logout</v-icon></v-btn
        >
      </template>
    </s-navigation-app-bar>

    <s-navigation-drawer
      ref="drawer"
      v-if="connectedAndAllowed"
      :items="menu"
    />
    <v-main>
      <v-container class="pa-0" fluid>
        <slot />
      </v-container>
    </v-main>
    <s-navigation-footer />
  </v-app>
  </div>
</template>

<script setup>
const drawer = ref(null);
const { connectedAndAllowed, fullName } = useAuth();

const menu = [
  {
    name: "menu.register1",
    icon: "mdi-plus",
    path: "/register/multiple",
    right: ["User","AdministratorRw"],
  },
  {
    name: "menu.register0",
    icon: "mdi-plus",
    path: "/register/zero",
    right: ["User","AdministratorRw"],
  },
  {
    name: "menu.registredcases",
    icon: "mdi-list-box-outline",
    children: [
      {
        name: "menu.registredcases.byyear",
        path: "/cases/by-year",
        icon: "mdi-view-list",
        right: ["User","AdministratorRw", "AdministratorRo"],
      },
      {
        name: "menu.registredcases.allcases",
        path: "/cases/all",
        icon: "mdi-menu",
        right: ["User","AdministratorRw", "AdministratorRo"],
      }     
    ],
  },
  {
    name: "menu.dashboard",
    icon: "mdi-view-dashboard",
    path : "/dashboard",
    right: ["AdministratorRw", "AdministratorRo"],
    children: [
    {
      name: "menu.dashboardglobal",
      path: "/dashboard",
      icon: "mdi-account-multiple",
    },
    {
      name: "menu.dashboardbyuser",
      path: "/dashboard/by-user",
      icon: "mdi-account-outline",
    },
    {
      name: "menu.dashboardzerocase",
      path: "/dashboard/zero",
      icon: "mdi-email-outline",
    }
],
  },
  {
    name: "menu.dashboard",
    icon: "mdi-view-dashboard",
    path : "/dashboard",
    right: ["User"],
  },
 
  {
    name: "menu.reminderemail",
    icon: "mdi-bell",
    right: ["AdministratorRw", "AdministratorRo"],
    children: [
      {
        name: "menu.reminderemail.eid",
        path: "/reminder/eid",
        icon: "mdi-email-outline",
        right: ["AdministratorRw", "AdministratorRo"],
      },
      {
        name: "menu.reminderemail.hd",
        path: "/reminder/hd",
        icon: "mdi-email-outline",
        right: ["AdministratorRw", "AdministratorRo"],
      }     
    ],
  },
  {
    name: "menu.usermanagement",
    icon: "mdi-account-multiple-outline",
    children: [
      {
        name: "menu.newusers",
        path: "/administration/users/new",
        icon: "mdi-account-alert",
        right: ["AdministratorRw", "AdministratorRo"],
      },
      {
        name: "menu.assignedusers",
        path: "/administration/users/assigned",
        icon: "mdi-account-check",
        right: ["AdministratorRw", "AdministratorRo"],
      },
      {
        name: "menu.hdusers",
        path: "/administration/users/hd",
        icon: "mdi-account",
        right: ["AdministratorRw", "AdministratorRo"],
      },
    ],
  },
  {
    name: "menu.homepagenews",
    icon: "mdi-information",
    path : "/news",
    right: ["AdministratorRw", "AdministratorRo"],
  }
];

defineExpose({
  drawer,
});
</script>
